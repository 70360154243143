import { configureStore } from '@reduxjs/toolkit';
import loaderSlice from './slice/loaderReducer'
const store = configureStore({
    reducer: {
        loader: loaderSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export default store;