import { useState } from "react";
import Logo from "../../assets/main_logo.png";
import { FiEyeOff } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import AuthService from "../../services/AuthService";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { startLoading, stopLoading } from "../../App/slice/loaderReducer";
const Login = () => {
  const [userData, setUserData] = useState({
    phone_code: "",
    user_name: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(startLoading());
    navigate("employee_list")
    // try {
    //   const response = await AuthService.Login(userData);

    //   if (response.status == 200) {
    //     dispatch(stopLoading());
    //     navigate("/employee_list");
    //   } else {
    //     dispatch(stopLoading());
    //     console.log(response);
    //     if (response.data.msg == "User not verify") {
    //       Swal.fire({
    //         title: "This account is not verified",
    //         // showDenyButton: true,
    //         showCancelButton: true,
    //         confirmButtonText: "Verify",
    //         denyButtonText: `Don't save`,
    //       }).then((result) => {
    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
    //           Swal.fire({
    //             title: "Enter OTP sent to your mobile",
    //             input: "number",
    //             inputAttributes: {
    //               autocapitalize: "off",
    //               inputmode: "numeric",
    //               type: "number",
    //             },
    //             showCancelButton: true,
    //             confirmButtonText: "Confirm",
    //             showLoaderOnConfirm: true,
    //             preConfirm: async (otp) => {
    //               try {
    //                 const verifyOtpResponse = await AuthService.verifyOtp(
    //                   userData,
    //                   otp
    //                 );
    //                 console.log("verifyOtpResponse", verifyOtpResponse);

    //                 if (!verifyOtpResponse.ok) {
    //                   // Handle the error message returned by the server
    //                   return Swal.showValidationMessage(`
    //                     Error: ${verifyOtpResponse.data.msg}
    //                   `);
    //                 }

    //                 return verifyOtpResponse.data;
    //               } catch (error) {
    //                 Swal.showValidationMessage(`
    //                   Request failed: ${error.message}
    //                 `);
    //               }
    //             },
    //             allowOutsideClick: () => !Swal.isLoading(),
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //               Swal.fire({
    //                 title: "OTP verified!",
    //                 text: "You have successfully signed up.",
    //                 icon: "success",
    //               });
    //             }
    //           });
    //         } else if (result.isDenied) {
    //           Swal.fire("Changes are not saved", "", "info");
    //         }
    //       });
    //     } else {
    //       Swal.fire({
    //         title: "Login error",
    //         text: response.data.msg ? response.data.msg : response.data,
    //         icon: "error",
    //       });
    //     }
    //   }
    // } catch (error) {
    //   dispatch(stopLoading());
    //   Swal.fire({
    //     title: "Login error",
    //     text: error.message,
    //     icon: "error",
    //   });
    //   console.error("There was an error during sign up:", error);
    // }
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
      <div className="text-center pb-6 text-2xl font-semibold">Welcome</div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="user_name"
            className={`block mb-2 text-sm ${
              userData.user_name ? "text-gray-800" : "text-gray-600"
            }`}
          >
            User name
          </label>
          <div className="flex">
            {/* <PhoneInput
              country={"et"}
              value={userData.phone_code}
              inputStyle={{
                width: "60px",
                borderColor: "#475569",
                borderRadius: "0",
                color: "black",
               
              }}
              containerStyle={{
                borderBottomWidth: "2px",
                borderBottomColor: "white",
              }}
              buttonStyle={{
                borderColor: "#475569",
                backgroundColor: "#475569",
              }}
              placeholder="Enter here ..."
            /> */}
            <input
              required
              type="text"
              id="user_name"
              name="user_name"
              placeholder="user name"
              className="flex-1  border-b-2 border-white focus:outline-none focus:ring-0 px-2"
              value={userData.user_name}
              onChange={(e) =>
                setUserData({ ...userData, user_name: e.target.value })
              }
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="password"
            className={`block mb-2 text-sm ${
              userData.password ? "text-gray-800" : "text-gray-600"
            }`}
          >
            Password
          </label>
          <div className="relative">
            <input
              required
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Password"
              className="w-full  border-b-2 border-white focus:outline-none focus:ring-0 px-2"
              value={userData.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 px-3 py-1 text-gray-700 border-b-2 border-white"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FiEyeOff color={"white"} />
              ) : (
                <FiEye color={"white"} />
              )}
            </button>
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-green-950 text-white py-2 px-4 rounded"
        >
          Log In
        </button>
      </form>
    </div>
  </div>
  
  );
};

export default Login;
