import { Routes, Route } from "react-router-dom";
import { routes, privateRoutes } from "./routes/routes";
import PrivateRoute from "./routes/PrivateRoute";


import { useState } from "react";
import './App.css'
import { HashLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "./components";
import EmployeeList from "./components/employee/EmployeeList";


const App = () => {
 
  let [color, setColor] = useState("#ffffff");
  const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader.loading);
    console.log('isLoading',isLoading)
  return (
    <div className="">
    {/* {!isLoading?<div className="loader">
    <HashLoader
        color={color}
        loading={true}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>:null} */}
      <Routes>
      
        <Route path="/" element={<Login />} />
        <Route path="employee_list" element={<EmployeeList />} />
      
        {/* <Route path="/" element={<Layout />}> */}
        
          {/* <Route element={<PrivateRoute />}> */}
           
          {/* </Route> */}
        {/* </Route> */}
      </Routes>
    </div>
  );
};

export default App;
