import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { HiTrash } from "react-icons/hi2";
import { RiEdit2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

function EmployeeList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [departments, setdepartments] = useState([]);
  const [allPositions, setAllPositions] = useState([]);
  const [positions, setpositions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  console.log(data);

  const fetchEmployees = async () => {
    fetch("http://161.35.109.36:3000/api/employees")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setFilteredData(data); // Initialize filteredData with the fetched data
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
  const [selectedidentity_no, setselectedidentity_no] = useState();
  const [selectedDepartment, setselectedDepartment] = useState();
  const [selectedPosition, setselectedPosition] = useState();
  const [showUpdateModal, setshowUpdateModal] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [positiondropdownVisible, setPositionDropdownVisible] = useState(false);


  const fetchDepartment = async () => {
    fetch("http://161.35.109.36:3000/api/employees/departments")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setdepartments(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const fetchAllPositions = async () => {
    setpositions([])
    setselectedPosition()
    fetch(`http://161.35.109.36:3000/api/employees/position/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
       console.log("wdddwdwd",data)
        setAllPositions(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };


  const fetchPosition = async (unit_id) => {
    setpositions([])
    setselectedPosition()
    fetch(`http://161.35.109.36:3000/api/employees/position/${unit_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log("sssss", data);
        setpositions(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  

  useEffect(() => {
    fetchEmployees();
    fetchDepartment();
    fetchAllPositions();
  }, []);


  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `http://161.35.109.36:3000/api/employees/${id}`;

        fetch(url, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.ok) {
              Swal.fire("Deleted!", "Your record has been deleted.", "success");
              fetchEmployees();
            } else {
              return response.json().then((error) => {
                Swal.fire(
                  "Error!",
                  `Failed to delete the record: ${error.message}`,
                  "error"
                );
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            Swal.fire(
              "Error!",
              "There was an issue deleting the record.",
              "error"
            );
          });
      }
    });
  };

  const getDepartmentName = (id) => {
    const selected = departments.filter((item) => item.id == id);
    return selected[0]?.name;
  };
  const getPositionName = (id) => {
    const selected = allPositions.filter((item) => item.id == id);
    return selected[0]?.name;
  };
const updatePosition=(identity_no,selectedDepartment,selectedPosition)=>{
  const url =
  "http://161.35.109.36:3000/api/employees/hrms/change-department";
const data = {
  user_id: identity_no,
  unit_id: selectedDepartment,
  positionclass_id: selectedPosition,
};
console.log("datawwww", data);
fetch(url, {
  method: "PATCH",
  headers: {
    "Content-Type": "application/json",
    // Add any additional headers if needed, e.g., Authorization
  },
  body: JSON.stringify(data),
})
  .then((response) => response.json())
  .then((result) => {
    handleUpdate(identity_no, result); // Call the handleUpdate callback function with the result
    Swal.fire(
      "Updated!",
      "The department and position have been changed.",
      "success"
    );
    setshowUpdateModal(false)
  })
  .catch((error) => {
    console.error("Error:", error);
    Swal.fire(
      "Error!",
      "There was an issue updating the department and position.",
      "error"
    );
  });
}

 


  const handleUpdate = async (identity_no, departmentidentity_no) => {
    try {
      // Make an API request to update the department of the record with the given identity_no
      // const response = await axios.put(`/api/records/${identity_no}`, {
      //   departmentidentity_no: departmentidentity_no
      // });
      // // Handle success
      // if (response.status === 200) {
 
      //   // You can also refresh data or perform additional actions here
      // }
    } catch (error) {
      // Handle error
      console.error("Error updating department:", error);
      Swal.fire(
        "Error!",
        "There was a problem updating the department.",
        "error"
      );
    }
  };

  const filtereData = () => {
    setFilteredData(
      data.filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.identity_no.toString().includes(searchQuery)
      )
    );
  };

  useEffect(() => {
    filtereData();
  }, [searchQuery, data]); // Add data as a dependency to refresh filteredData when data changes

  const TableComponent = ({ data }) => {
    return (
      <div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">
                  identity_no{filteredData.length}
                </th>
                <th className="py-2 px-4 border-b text-left">first_name</th>
                {/* <th className="py-2 px-4 border-b text-left">father_name</th> */}
                <th className="py-2 px-4 border-b text-left">Department</th>
                <th className="py-2 px-4 border-b text-left">Position</th>
                <th className="py-2 px-4 border-b text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData &&
                filteredData.filter(item=>item.is_terminated==false).map((item) => (
                  <tr key={item.identity_no}>
                    <td className="py-2 px-4 border-b">{item.identity_no}</td>
                    <td className="py-2 px-4 border-b">
                      {item.first_name} {item.father_name}{" "}
                      {item.grandfather_name}
                    </td>
                    {/* <td className="py-2 px-4 border-b">{item.father_name}</td> */}
                    <td className="py-2 px-4 border-b">
                      {getDepartmentName(item.unit_id)}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {getPositionName(item.position_id)}
                    </td>
                    <td className="py-2 px-4 border-b gap-5 flex">
                      <button
                        onClick={() =>{setshowUpdateModal(true)
                          setselectedidentity_no(item.id)
                          // confirmChangeDepartment(
                          //   item.id,
                          //   departments,
                          //   positions,
                          //   handleUpdate
                          // )}
                        } }
                        className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-700 transition duration-200"
                      >
                        <RiEdit2Fill />
                      </button>
                      <button
                        onClick={() => confirmDelete(item.id)}
                        className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700 transition duration-200"
                      >
                        <HiTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              {filteredData.length === 0 && (
                <tr>
                  <td colSpan="4" className="py-4 px-4 text-center">
                    No results found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };



  const handleSelectDepartment = (department) => {

    setselectedDepartment(department);
    // setpositions()
    fetchPosition(department.id)
    setDropdownVisible(false)
  };
  const handleSelectPosition = (position) => {
    
    setselectedPosition(position);
   
    setPositionDropdownVisible(false); // Close the dropdown after selection
  };
console.log("sdddsdsdsds", selectedPosition)
  return (
    <div className="container p-5 mx-auto">
      <button
        onClick={() => {
          navigate("/");
        }}
        className="bg-red-600 px-5 py-1 rounded-lg text-white"
      >
        logout
      </button>
      <div className="flex justify-end">
        <input
          type="text"
          placeholder="Search by first_name or identity_no"
          className="mb-4 p-2 border border-gray-300  w-1/2 md:w-1/3 rounded-2xl px-5 "
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      {showUpdateModal?<div className="bg-slate-500 fixed top-0 left-0 w-full h-[100vh] items-center flex justify-center ">
        <div className="bg-white place-content-center p-5 rounded-lg">
          <p className="text-center font-bold pb-3 text-2xl">Change Department and Position</p>
          <div className="relative inline-block text-left w-full">
            <button
              onClick={() => setDropdownVisible(!dropdownVisible)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex w-full"
            >
              {selectedDepartment?.name || "Select Department"}
            </button>

            {dropdownVisible && (
              <div className="absolute mt-2 bg-white border rounded shadow-lg w-full max-h-[150px] overflow-scroll ">
                <div className="row">
                  {departments.map((department) => (
                    <button
                      key={department.id}
                      onClick={() => {handleSelectDepartment(department)
                       
                        }}
                      className="col block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left border-b-2"
                    >
                      {department.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
            {positions.length>0?<button
              onClick={() => setPositionDropdownVisible(!positiondropdownVisible)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex w-full mt-3"
            >
              {selectedPosition?.name || "Select Position"}
            </button>:selectedDepartment?<div className="text-center pt-2">Sorry, There is no avalable position listed under selected department </div>:null}

            {positiondropdownVisible && (
              <div className="absolute mt-2 bg-white border rounded shadow-lg w-full max-h-[150px] overflow-scroll ">
                <div className="row">
                  {positions.map((position) => (
                    <button
                      key={position.id}
                      onClick={() => {handleSelectPosition(position)
                        // console.log("sss",department)
                        }}
                      className="col block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left border-b-2"
                    >
                      {position.name}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="flex gap-5 justify-center pt-5">
              <button className="bg-green-700 px-3 py-2 rounded-lg text-white" onClick={()=>selectedDepartment && selectedPosition?updatePosition(selectedidentity_no, selectedDepartment.id, selectedPosition.id):Swal.fire(
        "Error!",
        "Please select department and position first",
        "error"
      )}> Update</button>
              <button className="bg-red-700 px-3 py-2 rounded-lg text-white" onClick={()=>setshowUpdateModal(false)}> Cancel</button>
              
            </div>
          </div>
        </div>
      </div>:null}
      <TableComponent data={filteredData} />
    </div>
  );
}

export default EmployeeList;
